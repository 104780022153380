import React from 'react';
import Sidebar from './SideBar';
import AppBar from '../Components/PheonixAppBar';
import LayoutProps from "../interfaces/LayoutProps";
import { useSidebar } from './SidebarContext';  

const Layout: React.FC<LayoutProps> = ({ title, children }) => {
  const { isOpen, toggleSidebar } = useSidebar();
 return (
    <div style={{ flexGrow: 1 ,overflow:'hidden'}}>
      <AppBar title={title} isSidebarOpen={isOpen} />
      <div style={{ display: 'flex' }}>
        <Sidebar isOpen={isOpen} onToggle={toggleSidebar} />
        <div style={{ flexGrow: 1, backgroundColor: 'white',overflow:'hidden', borderLeft: "1px solid #ccc", border: "1px solid #FFFFFF", borderRadius:"10px" ,width:"10%"}}>
          {children}
        </div>
      </div>
    </div>
  );
};
export default Layout;
import { gql } from "@apollo/client";
export const GET_INVOICE_QUERY = `
query GetInvoicebyUser($userId: ID!, $page: Int!, $perPage: Int!, $searchQuery: String) {
  getInvoicebyUser(user_id: $userId, page: $page, perPage: $perPage, searchQuery: $searchQuery) {
    totalPages
    totalCount
    invoices {
      id
      user_id
      serial_no
      invoiceid
      invoicedate
      productOrService {
                      name
                      tax
                      amount
                      rate
                      quantity
                    }
      price
      paid_amount
      duedate
      status
      customer_name
      customer_id
      email
      description
      phone_no
      expiry_date
      invoice_no
      gstType
     due_amount
    }
  }
}
`;
export const GET_CUSTOMER_QUERY = gql`
  query GetAddedUsers($page: Int!, $perPage: Int!, $searchQuery: String) {
    getAddedUsers(page: $page, perPage: $perPage, searchQuery: $searchQuery) {
      totalPages
      totalCount
      users {
        _id
        user_id
        user_type
        customer_no
        active_status
        basic_info {
          firstname
          lastname
          email
          phone_number
          customer_type
          company_name
          shipping_address {
            address_line1
            city
            state
            pincode
            country
          }
        }
        billing_info {
          GST_type
          GST_no
          payment_terms
          currency
          business_name
        }
      }
    }
  }
`;

export const GET_CLIENT_DASHBOARD = `
query GetDashboardCounts($user_id: ID!) {
  getDashboardCounts(user_id: $user_id) {
    totalOrderCount
    totalQuotationCount
    totalInvoiceCount
  }
}`;

export const GET_ALL_CUSTOMERS = `
query GetAllUsers {
  getAllUsers {
           _id
        user_id
        user_type
        customer_no
        basic_info {
                firstname
                lastname
                email
                phone_number
                customer_type
                company_name
  
                shipping_address {
                    address_line1
                    city
                    state
                    pincode
                    country
                }
            }
                 billing_info {
                GST_type
                GST_no
                payment_terms
                currency
                business_name
            }
  }
}`;

export const GET_INVOICE_WITH_TYPE = gql`
  query GetInvoicebyUserType(
    $customer_id: ID!
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getInvoicebyUserType(
      customer_id: $customer_id
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      id
      message
      invoices {
        id
        serial_no
        user_id
        name
        invoiceid
        quotationid
        quotationNo
        orderid
        order_no
        invoicedate
        price
        paid_amount
        due_amount
        duedate
        status
        paymentdate
        bal_amount
        invoice_no
        customer_id
        phone_no
        email
        currency
        discount
        discountType
        taxamount
        customernotes
        terms_conditions
        order_status
        productOrService {
          name
          hsnCode
          tax
          productplan
          billcycle
          amount
          rate
          quantity
        }
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = `
query Getallproducts {
  getallproducts {
        name
        hsnCode
        productplan
        billcycle
        tax
        amount
        price
        rate
        quantity
  }
}`;
export const PROFILE_DATA = gql`
  query GetUserById($userId: ID!) {
    getUserById(id: $userId) {
      user_id
      basic_info {
        company_name
        customer_fullname
        contact_number
        email
      }
      contact_info {
        address
        city
        state
        country
        pincode
      }
      billing_info {
        GST_type
        GST_no
        currency
      }
    }
  }
`;
export const CLIENT_PAYMENTS = gql`
  query GetAllPaymentsbyCustomerId(
    $userId: ID!
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getAllPaymentsbyCustomerId(
      userId: $userId
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      success
      message
      payments {
        id
        customer_name
        customer_id
        customer_no
        invoice_id
        paymentDue
        paymentMode
        discount
        discountType
        customernotes
        paidAmount
        creditAmount
        status
        dueAmount
        duedate
        invoicedate
        currency
        productOrService {
          name
          hsnCode
          productplan
          billcycle
          tax
          amount
          price
          rate
          quantity
          paid
        }
      }
      pagination {
        total
        page
        perPage
        totalPages
      }
    }
  }
`;
export const GET_QUOTATION_BY_USERTYPE = gql`
  query GetQuotationbyUserType(
    $customerId: ID!
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getQuotationbyUserType(
      customerId: $customerId
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      quotations {
        id
        serial_no
        user_id
        name
        quotationid
        quotationdate
        productOrService {
          name
          hsnCode
          productplan
          billcycle
          tax
          amount
          rate
          quantity
        }
        duedate
        quotationnotes
        customernotes
        customerName
        customerNo
        customerId
        phoneNumber
        email
        terms_conditions
        currency
        address
        price
        discount
        discountType
        taxamount
        status
      }
    }
  }
`;

export const GET_ORDER_BY_USERTYPE = gql`
  query GetOrderbyUserType(
    $customerId: ID!
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getOrderbyUserType(
      customerId: $customerId
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      orders {
        id
        serial_no
        user_id
        name
        orderid
        orderdate
        price
        duedate
        status
        invoiceno
        email
        currency
        discount
        discountType
        taxamount
        customernotes
        terms_conditions
      }
    }
  }
`;
export const GET_QUOTATIONS_BY_USERTYPE = gql`
  query GetQuotationbyUserType(
    $customerId: ID!
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getQuotationbyUserType(
      customerId: $customerId
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      quotations {
        id
        serial_no
        user_id
        name
        quotationid
        quotationdate
        productOrService {
          name
          hsnCode
          productplan
          billcycle
          tax
          amount
          rate
          quantity
        }
        duedate
        quotationnotes
        customernotes
        customerName
        customerNo
        customerId
        phoneNumber
        email
        terms_conditions
        currency
        address
        price
        discount
        discountType
        taxamount
        status
      }
    }
  }
`;
export const REJECT_QUOTATION = gql`
  mutation RejectQuotation($quotationId: ID!) {
    rejectQuotation(_id: $quotationId) {
      id
      serial_no
      user_id
      name
      quotationid
      quotationdate
      productOrService {
        name
        hsnCode
        productplan
        billcycle
        tax
        amount
        rate
        quantity
      }
      duedate
      address
      price
      status
    }
  }
`;

export const ACCEPT_QUOTATION = gql`
  mutation AcceptQuotation($quotationId: ID!) {
    acceptQuotation(_id: $quotationId) {
      id
      serial_no
      user_id
      name
      quotationid
      quotationdate
      productOrService {
        name
        hsnCode
        productplan
        billcycle
        tax
        amount
        rate
        quantity
      }
      duedate
      address
      price
      status
    }
  }
`;
export const GET_QUOTATIONS_BY_USERBYDRAFT = gql`
  query getQuotationbyUserByDraft(
    $customerId: ID!
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getQuotationbyUserByDraft(
      customerId: $customerId
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      quotations {
        id
        serial_no
        user_id
        name
        quotationid
        quotationdate
        productOrService {
          name
          hsnCode
          productplan
          billcycle
          tax
          amount
          rate
          quantity
        }
        duedate
        quotationnotes
        customernotes
        customerName
        customerNo
        customerId
        phoneNumber
        email
        terms_conditions
        currency
        address
        price
        discount
        discountType
        taxamount
        status
      }
    }
  }
`;
export const GET_INVOICE_WITH_TYPEBYDRAFT = gql`
  query getInvoicebyUserByDraft(
    $customer_id: ID!
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getInvoicebyUserByDraft(
      customer_id: $customer_id
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      id
      message
      invoices {
        id
        serial_no
        user_id
        name
        invoiceid
        quotationid
        quotationNo
        orderid
        order_no
        invoicedate
        price
        paid_amount
        due_amount
        duedate
        status
        paymentdate
        bal_amount
        invoice_no
        customer_id
        phone_no
        email
        currency
        discount
        discountType
        taxamount
        customernotes
        terms_conditions
        order_status
        productOrService {
          name
          hsnCode
          tax
          productplan
          billcycle
          amount
          rate
          quantity
        }
      }
    }
  }
`;

export const GET_PRODUCTS_BY_TYPE = gql`
  query GetProductsByType($type: String!) {
    getProductsByType(type: $type) {
      id
      type
      name
      hsnCode
      description
      benefit
      url
      image
      module
      remarks
      status
      updatedAt
      plans {
        name
        features
        pricing {
          currency
          tld
          rate
          quarterly
          semiAnnually
          biAnnually
          annually
          triAnnually
        }
      }
    }
  }
`;


export const GET_CART_DETAILS = gql`
  query GetCart($customerId: ID!) {
    getCart(customerId: $customerId) {
      id
      customerId
      productIds
      createdAt
    }
  }
`;


export const GET_PRODUCTS_BY_CUSTOMER = gql`
  query GetProductsByCustomer(
    $customerId: ID!
    $page: Int!
    $perPage: Int!
    $searchQuery: String
  ) {
    getProductsByCustomer(
      customerId: $customerId
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      productandservices {
        order_id
        orderid
        currency
        duedate
        name
        hsnCode
        productplan
        billcycle
        tax
        amount
        rate
        quantity
        id
        productDetails {
          id
          type
          name
          hsnCode
          description
          benefit
          url
          image
          fileName
          module
          remarks
          status
          updatedAt
          plans {
            name
            features
            pricing {
              currency
              tld
              rate
              quarterly
              semiAnnually
              biAnnually
              annually
              triAnnually
            }
          }
        }
        promocodes
        invoiceno
      }
    }
  }
`;

export const GET_ALL_PRODUCTS_FOR_CUSTOMER = gql`
  query GetAllProductsForCustomer($customerId: ID!) {
    getAllProductsForCustomer(customerId: $customerId) {
      Order {
        order_id
        orderid
        orderdate
        invoiceno
        currency
        duedate
        status
        name
        companyname
        address
        city
        state
        country
        GST
        paidamount
        hsnCode
        productplan
        billcycle
        tax
        amount
        rate
        quantity
        id
        promocodes
        productDetails {
          id
          type
          name
          hsnCode
          description
          benefit
          url
          image
          fileName
          module
          remarks
          status
          updatedAt
          planHistory {
            plan
            rate
            updatedAt
          }
        }
      }
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query GetUserById($userId: ID!) {
    getUserById(id: $userId) {
      user_id
      basic_info {
        company_name
        customer_fullname
        contact_number
        email
      }
      contact_info {
        address
        city
        state
        country
        pincode
      }
      billing_info {
        GST_type
        GST_no
        currency
      }
    }
  }
`;

export const GET_ALL_PRODUCTS_AND_SERVICES = `
  query GetAllProductsServices {
    getAllProductsServices {
      id
      type
      name
      hsnCode
      description
      benefit
      url
      image
      fileName
      module
      remarks
      status
      updatedAt
      plans {
        name
        features
        pricing {
          currency
          tld
          rate
          quarterly
          semiAnnually
          biAnnually
          annually
          triAnnually
        }
      }
    }
  }
`;

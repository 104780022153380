import React, { useState } from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  Box,
  Paper,
  TextField,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { TEXT_MESSAGES } from "../../const";
interface UserDetailsInterface {
  customerId: string;
  customerName: string;
  email: string;
  phoneNumber: string;
  address: string;
  companyName: string;
}

interface CustomerDetailsProps {
  userDetails: UserDetailsInterface;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({ userDetails }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [expandedBox, setExpandedBox] = useState(null);

  const handleToggle = (boxId: any) => {
    setExpandedBox(expandedBox === boxId ? null : boxId);
  };

  const userData = {
    firstName: "John Doe",
    phoneNumber: "+1 (555) 123-4567",
    email:"abc@gmail.com",
    address: "123 Main Street, Anytown, USA 12345",
  };

  return (
    <Box
      sx={{
        width: "95%",
        padding: "10px",
        margin: "0 auto",
        maxHeight: "40vh",
        overflow: "auto",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": { height: "4px" },
        "&::-webkit-scrollbar-thumb": {
          background: "#EFEFFF",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-track": { background: "transparent" },
      }}
    >
      <Paper
        elevation={0}
        sx={{
          mb: 2,
          border: "0.5px solid #7A80FF",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 2,
            borderBottom:
              expandedBox === "login" ? "1px solid #7A80FF" : "none",
            position: "relative",
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "20px",
                color: "#7A80FF",
              }}
            >
             {TEXT_MESSAGES.LOGIN_INFO}
            </Typography>
          </Box>

          <IconButton onClick={() => handleToggle("login")} sx={{ ml: "auto" }}>
            {expandedBox === "login" ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </Box>

        {expandedBox === "login" && (
          <Box sx={{ p: 3 }}>
            <Box
              sx={{
                p: 1,
                borderRadius: "8px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 1,
                      fontWeight: 500,
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                    }}
                  >
                   {TEXT_MESSAGES.FULL_NAME}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                    }}
                  >
                    {userDetails.customerName ? userDetails.customerName : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 1,
                      fontWeight: 500,
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                    }}
                  >
                    {TEXT_MESSAGES.CONTACT_NUMBER}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                    }}
                  >
                    {userDetails.phoneNumber ? userDetails.phoneNumber : "NA"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 1,
                      fontWeight: 500,
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                    }}
                  >
                    {TEXT_MESSAGES.EMAIL_ID}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                    }}
                  >
                    {userDetails.email ? userDetails.email : "NA"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Paper>

      <Paper
        elevation={0}
        sx={{
          mb: 2,
          border: "0.5px solid #7A80FF",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 2,
            borderBottom:
              expandedBox === "contact" ? "1px solid #7A80FF" : "none",
            position: "relative",
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "20px",
                color: "#7A80FF",
              }}
            >
              {TEXT_MESSAGES.CONTACT_INFOS}
            </Typography>
          </Box>

          <IconButton
            onClick={() => handleToggle("contact")}
            sx={{ ml: "auto" }}
          >
            {expandedBox === "contact" ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </Box>

        {expandedBox === "contact" && (
          <Box sx={{ p: 3 }}>
            <Box
              sx={{
                borderRadius: "8px",
                m: 1,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  fontWeight: 400,
                  fontFamily: "Inter",
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#000000",
                }}
              >
                {TEXT_MESSAGES.ADDRESSS}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 400,
                  fontFamily: "Inter",
                  fontSize: "14px",
                  lineHeight: "16.94px",
                  color: "#000000",
                }}
              >
                {userDetails.address ? userDetails.address : "NA"}
              </Typography>
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  );
};
export default CustomerDetails;

import { Typography, Modal, Button, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import logo from "../assets/logo.jpg";
import paymentimg from "../assets/paymentimg.png";

interface PaymentDetailsModalProps {
  open: boolean;
  onClose: () => void;
  product: any;
}

const PaymentDetailsModal: React.FC<PaymentDetailsModalProps> = ({
  open,
  product,
  onClose,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "65%",
          left: "79%",
          transform: "translate(-50%, -50%)",
          width: 588,
          height: 905,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          overflowY: "auto",
          fontFamily: "inter",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex">
            <img
              src={paymentimg}
              alt="payment"
              style={{
                width: "50px",
                height: "52px",
                marginTop: "15px",
                marginRight: "10px",
              }}
            />
            <Box sx={{ mt: 3 }}>
              <Typography fontWeight={400} fontSize={12}>
                Invoice Number:{" "}
                <span style={{ fontWeight: 600, fontSize: "12px" }}>
                  {product.invoiceno}
                </span>
              </Typography>
              <Typography fontWeight={400} fontSize={12} mb={2}>
                Date:{" "}
                <strong style={{ fontWeight: 600, fontSize: "12px" }}>
                  {product.orderdate}
                </strong>
              </Typography>
            </Box>
          </Box>
          <img src={logo} alt="Phoenix Solutions" width={75} height={50} />
        </Box>

        <Box display="flex" justifyContent="space-between" sx={{ mt: 2, width: "100%" }}>
  <Box sx={{ flex: 1,display: "flex" }}>
    <Typography fontWeight={400} fontSize={11} fontFamily={"inter"}>
      To:
    </Typography>
    <Box sx={{ marginLeft: "20px" }}>
      <Typography sx={{ fontSize: "11px", fontWeight: 500, fontFamily: "inter" }}>
        {product.name}
      </Typography>
      {(product.address || product.state || product.city || product.country) && (
        <Typography sx={{ fontSize: "11px", fontWeight: 500, fontFamily: "inter" }}>
          {product.address}, {product.state}, {product.city}, {product.country}
        </Typography>
      )}
      {product.GST && (
        <Typography sx={{ fontSize: "11px", fontWeight: 500, fontFamily: "inter" }}>
          GST Number: {product.GST}
        </Typography>
      )}
    </Box>
  </Box>

  <Box  sx={{ flex: 1,display: "flex", justifyContent: "flex-end" }}>
    <Typography fontWeight={400} fontSize={11} fontFamily={"inter"}>
      From:
    </Typography>
    <Box sx={{ marginLeft: "20px" }}>
      <Typography sx={{ fontSize: "11px", fontWeight: 500, fontFamily: "inter" }}>
        Phoenix Solutions
      </Typography>
      <Typography sx={{ fontSize: "11px", fontWeight: 500, fontFamily: "inter" }}>
        Erode, Tamil Nadu, India
      </Typography>
      <Typography sx={{ fontSize: "11px", fontWeight: 500, fontFamily: "inter" }}>
        GST Number: 93794959584
      </Typography>
    </Box>
  </Box>
</Box>


        <Box display="flex">
          <Box
            sx={{
              mt: 3,
              p: 2,
              bgcolor: "#F8F8FE",
              borderRadius: 2,
              display: "flex",
              justifyContent: "space-between",
              width: "267px",
              height: "126px",
            }}
          >
            <Box display="flex">
              <Box mr={3}>
                <Typography fontWeight={400} fontSize={11} mt={2}>
                  Organisation Name
                </Typography>
                <Typography fontWeight={500} fontSize={11}>
                  {product.companyname || product.name}
                </Typography>
                <Typography fontWeight={400} fontSize={11} mt={2}>
                  Product / Service
                </Typography>
                <Typography fontWeight={500} fontSize={11}>
                  {product.productDetails.name}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight={400} fontSize={11} mt={2}>
                  Customer Name
                </Typography>
                <Typography fontWeight={500} fontSize={11}>
                  {product.name}
                </Typography>
                <Typography fontWeight={400} fontSize={11} mt={2}>
                  Plan and Pricing
                </Typography>
                <Typography fontWeight={500} fontSize={11}>
                  {product.productplan} - {product.billcycle}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              ml: 2,
              mt: 3,
              p: 2,
              bgcolor: "#F8F8FE",
              borderRadius: 2,
              display: "flex",
              justifyContent: "space-between",
              width: "267px",
              height: "126px",
            }}
          >
            <Box display="flex">
              <Box mr={3} ml={2}>
                <Typography fontWeight={400} fontSize={11} mt={2}>
                  Plan Amount
                </Typography>
                <Typography fontWeight={500} fontSize={11}>
                  {product.amount}
                </Typography>
                <Typography fontWeight={400} fontSize={11} mt={2}>
                  Payment Status
                </Typography>

                <Typography
                  sx={{
                    bgcolor: product.status === "Paid" ? "green" : "#FFD9D7",
                    p: 1,
                    borderRadius: 1,
                    display: "inline-block",
                    fontSize: "11px",
                    fontWeight: 500,
                    color: product.status === "Paid" ? "white" : "black",
                  }}
                >
                  {product.status}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight={400} fontSize={11} mt={2}>
                  Actual Amount
                </Typography>
                <Typography fontWeight={500} fontSize={11}>
                  {product.quantity * product.rate}
                </Typography>
                <Typography fontWeight={400} fontSize={11} mt={2}>
                  Paid Amount
                </Typography>
                <Typography fontWeight={500} fontSize={11}>
                  {" "}
                  {product.status === "Paid"
                    ? product.amount
                    : product.status === "Unpaid" || "Draft"
                    ? 0
                    : product.paidamount || 0}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={3}>
          <Typography fontWeight={600} fontSize={14} mb={0.5} color={"#181D8C"}>
            {product.productDetails.name}
          </Typography>
          <Typography
            sx={{
              display: "inline-block",
              bgcolor: "#7A80FF",
              px: 1,
              py: 0.5,
              color: "white",
              fontSize: "14px",
              fontWeight: 400,
              borderRadius: "8px",
              mr: 1,
            }}
          >
            {product.productplan}
          </Typography>
          <Typography
            sx={{
              display: "inline-block",
              color: "black",
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Validity - {product.duedate}
          </Typography>
        </Box>
        {product.productDetails.description && (
          <Box mt={2}>
            <Typography fontWeight={500} fontSize={12} mb={1}>
              Product Details
            </Typography>
            <Typography fontWeight={400} fontSize={12} mb={1}>
              {product.productDetails.description ||
                product.productDetails.benefit}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            mt: "auto",
            display: "flex",
            justifyContent: "flex-end",
            pt: 2,
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#181D8C",
              height: "30px",
              width: "50px",
              fontSize: "12px",
            }}
            onClick={onClose}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default PaymentDetailsModal;

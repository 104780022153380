import React, { useState } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { TEXT_MESSAGES } from "../../const";
import PheonixStepper from "./PheonixStepperComponent";
const MyCart: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
        <PheonixStepper />
    </>
  );
};
export default MyCart;

import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { api } from "../../api";
import axios from "axios";
import Order from "./Order";
import PheonixPaper from "../../Components/PheonixPaper";
import { useTheme } from "@mui/material/styles";
import DeleteModal from "../../Components/PheonixDeleteModal";
import PheonixDataGrid from "../../Components/PheonixDataGrid";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { Typography, Box, IconButton } from "@mui/material";
import { TEXT_MESSAGES } from "../../const";
import { EDIT_ORDER_MUTATION, DELETE_ORDER } from "../../graphql/mutation";
import PheonixButton from "../../Components/PheonixButton";
import OrderProps from "../../interfaces/OrderProps";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../assets/logo.jpg";

import PheonixSnackBar from "../../Components/PheonixSnackBar";
import PheonixBreadcrumbs from "../../Components/PheonixBreadcrumbs";
import { useQuery } from "@apollo/client";
import { GET_ORDER_BY_USERTYPE } from "../../graphql/query";

interface OrderData {
  "Company Name": string;
  "Customer Name": string;
  "Contact Number": string;
  "Contact Email ID": string;
  "Product/Service": string;
  Plan: string;
  "Order Specific Details": string;
  "Customer Id": string;
  "Renewal Date"?: string;
  "Actual Amount": string;
  "Proposed Amount": string;
}

interface Field {
  label: keyof OrderData;
  type: string;
}

const initialCustomerData: OrderData = {
  "Company Name": "",
  "Customer Name": "",
  "Contact Number": "",
  "Contact Email ID": "",
  "Product/Service": "",
  Plan: "",
  "Order Specific Details": "",
  "Customer Id": "",
  "Renewal Date": "",
  "Actual Amount": "",
  "Proposed Amount": "",
};

const Orders: React.FC = () => {
  const theme = useTheme();
  const [ordersData, setOrdersData] = useState<Order[]>([]);
  const [filteredOrdersData, setFilteredOrdersData] = useState<Order[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [error, setError] = useState("");
  const [editid, setEditId] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [dataLoading, setdataLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [succMessage, setSuccMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [editMode, setIsEditMode] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");
  const [customerData, setCustomerData] =
    useState<OrderData>(initialCustomerData);
  const [apiresponse, setApiresponse] = useState([]);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
  };

  useEffect(() => {
    orderRefetch();
  }, [
    currentPage,
    paginationModel.page,
    paginationModel.pageSize,
    searchQuery,
  ]);

  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };

  const columns = [
    { field: "serial_no", headerName: "S.No", width: 70 },
    { field: "orderid", headerName: "Order ID", width: 150 },

    { field: "orderdate", headerName: "Order Date", width: 150 },
    { field: "invoiceno", headerName: "Invoice Number", width: 175 },
    {
      field: "",
      headerName: "Amount Due",
      width: 175,
      renderCell: (params: any) => {
        return params.value != null ? `₹ ${params.value}` : "NA";
      },
    },
    { field: "duedate", headerName: "Due Date", width: 150 },
    { field: "status", headerName: "Status", width: 120 },
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const user_id = localStorage.getItem("userId");
  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
    refetch: orderRefetch,
  } = useQuery(GET_ORDER_BY_USERTYPE, {
    variables: {
      customerId: user_id,
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery,
    },
    onCompleted: (data) => {
      const orderWithSerialNo = data.getOrderbyUserType.orders.map(
        (order: any, index: number) => ({
          ...order,
          serial_no: (
            paginationModel.page * paginationModel.pageSize +
            index +
            1
          ).toString(),
        })
      );
      setOrdersData(orderWithSerialNo);
      setTotalPages(data.getOrderbyUserType.totalCount);
    },
  });
  useEffect(() => {
    if (!orderLoading && !orderError && orderData) {
      const { totalPages, totalCount, order } = orderData.getOrderbyUserType;
      const orderWithSerialNo = orderData.getOrderbyUserType.orders.map(
        (order: any, index: number) => ({
          ...order,
          serial_no: (
            paginationModel.page * paginationModel.pageSize +
            index +
            1
          ).toString(),
        })
      );
      setOrdersData(orderWithSerialNo);
      setTotalPages(orderData.getOrderbyUserType.totalCount);
    }
  }, [
    orderData,
    orderLoading,
    orderError,
    paginationModel.page,
    paginationModel.pageSize,
    searchQuery,
  ]);

  const fields: Field[] = [
    { label: "Customer Name", type: "text" },
    { label: "Company Name", type: "text" },
    { label: "Contact Number", type: "number" },
    { label: "Contact Email ID", type: "text" },
    { label: "Product/Service", type: "text" },
    { label: "Renewal Date", type: "text" },
    { label: "Plan", type: "text" },
    { label: "Order Specific Details", type: "text" },
    { label: "Actual Amount", type: "text" },
    { label: "Proposed Amount", type: "text" },
  ];

  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "85vh",
        padding: "10px 20px 10px 20px",
        maxWidth: isSidebarOpen ? "calc(100% - 70px)" : "calc(100% - 80px)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              color: "#181D8C",
              fontWeight: 600,
              textAlign: "center",
              lineHeight: "21px",
            }}
          >
            {TEXT_MESSAGES.ORDER}
          </Typography>
          <PheonixBreadcrumbs />
        </div>
        <div style={{ marginRight: "-5px" }}>
          {loggedInUser === "1" && (
            <PheonixButton
              style={{
                marginRight: "10px",
                color: "white",
                border: "1px solid #1C1B1F",
                backgroundColor: "#181D8C",
              }}
              onClick={handleOpen}
              label={TEXT_MESSAGES.NEW_ORDER}
            />
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          border: "0.5px solid #B7BAFF",
        }}
      >
        <PheonixDataGrid
          rows={ordersData}
          columns={columns}
          pageSizeOptions={[5, 10, 20, 30]}
          rowCount={totalPages}
          style={{ border: "0px", marginLeft: "20px" }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          onSearchQueryChange={handleSearchQueryChange}
          searchQuery={searchQuery}
          loading={orderLoading}
        />
      </div>

      <PheonixSnackBar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={succMessage}
        vertical="top"
        horizontal="center"
      />
    </div>
  );
};
export default Orders;

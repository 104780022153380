import React, { useState, useEffect } from "react";
import {TEXT_MESSAGES} from "../const";
const PheonixFooter: React.FC<PheonixFooterProps> = ({ style }) => {
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div style={{display:"flex"}}>
    <div
      style={{
        textAlign: "left",
        fontFamily: "Roboto",
        color: " #212121",
        fontSize: "12px",
        fontWeight: 400,
        marginTop: "3px",
        position: "fixed",
        bottom: 8,
   ... style
      
      }}
    >
      &copy;{currentYear}
      <span> {TEXT_MESSAGES.FOOTER_TXT}</span>{" "}
      <a
        href="https://www.pheonixsolutions.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#181D8C",
          fontFamily: "Roboto",
          fontWeight: 400,
          textDecoration: "none",
          fontSize: "12px",
        }}
      >
        {TEXT_MESSAGES.PHEONIX_TXT}
      </a>
    </div>
    <a
    href="https://www.pheonixsolutions.com/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      style={{
        textAlign: "right",
        fontFamily: "Roboto",
        color: " #212121",
        fontSize: "12px",
        fontWeight: 400,
        marginTop: "1px",
        position: "fixed",
        bottom: 10,
        right: 30      
      }}
    >
{TEXT_MESSAGES.TERM_TEXT}
    </a>
    </div>
  );
};
export default PheonixFooter;

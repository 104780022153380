import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { api } from "../../api";
import axios from "axios";
import { StepIconProps } from "@mui/material/StepIcon";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery, useMutation } from "@apollo/client";
import SnackBar from "../../Components/PheonixSnackBar";
import { TEXT_MESSAGES, ERROR_MESSAGES } from "../../const";
import AddProductsComponent from "./CustomerDetails";
import CustomerDetails from "./CustomerDetails";
import ProductDetails from "./ProductDetails";
import Payment from "./PaymentComponent";
import { RowData } from "./ProductTableprops";
import { ApolloError } from "@apollo/client";
import {
  GET_USER_DETAILS,
  GET_ALL_PRODUCTS_AND_SERVICES,
  GET_CART_DETAILS,
} from "../../graphql/query";
import { ADD_INVOICE_MUTATION, REMOVE_FROM_CART } from "../../graphql/mutation";
import { RateReview } from "@mui/icons-material";

interface StepType {
  label: string;
  content: React.JSX.Element;
}

const CustomStepIcon: React.FC<StepIconProps> = ({
  active,
  completed,
  icon,
}) => {
  const stepLabels = ["A", "B", "C"];
  return (
    <Box
      sx={{
        width: 28,
        height: 28,
        borderRadius: "50%",
        backgroundColor: completed ? "#181D8C" : active ? "white" : "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: active ? "#181D8C" : completed ? "white" : "rgba(0, 0, 0, 0.86)",
        fontSize: "16px",
        border: active ? "1px solid #181D8C" : "none",
      }}
    >
      {typeof icon === "number" && stepLabels[icon - 1]}
    </Box>
  );
};

const PheonixStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const step = location.state?.step || "";
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [succMessage, setSuccMessage] = useState("");
  const [currency, setCurrency] = React.useState("");
  const [isEditMode] = useState(!!location.state?.productService);
  const [allproductsData, setAllProductsData] = useState<any[]>([]);
  const [error, setError] = useState("");
  const [orderDate, setOrderDate] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [productPlans, setProductPlans] = useState<{ [key: number]: any }>({});
  type FieldErrors = {
    [index: string]: {
      [fieldName: string]: string;
    };
  };
  const [tableFieldErrors, setTableFieldErrors] = useState<FieldErrors>({});
  const [addInvoiceMutation] = useMutation(ADD_INVOICE_MUTATION);
  const [removeFromCart] = useMutation(REMOVE_FROM_CART);

  const [rows, setRows] = useState<RowData[]>([
    {
      "Product Details": "",
      "Product Plan": "",
      "Billing Cycle": "",
      "HSN Number": "",
      Quantity: 0,
      Rate: 0,
    },
  ]);
  const [userDetails, setUserDetails] = useState({
    customerId: "",
    customerName: "",
    email: "",
    phoneNumber: "",
    address: "",
    companyName: "",
  });
  const userId = localStorage.getItem("userId");
  const {
    loading,
    error: userError,
    data,
  } = useQuery(GET_USER_DETAILS, {
    variables: { userId },
  });

  const {
    loading: cartLoading,
    error: cartError,
    data: cartData,
    refetch: cartRefetch,
  } = useQuery(GET_CART_DETAILS, {
    variables: { customerId: userId },
  });
  useEffect(() => {
    const emptyRow = {
      "Product Details": "",
      "Product Plan": "",
      "Billing Cycle": "",
      "HSN Number": "",
      Quantity: 0,
      Rate: 0,
      Amount: 0,
    };

    const newProductPlans: { [key: number]: any } = {};

    if (
      cartData &&
      cartData.getCart &&
      Array.isArray(cartData.getCart.productIds) &&
      cartData.getCart.productIds.length > 0
    ) {
      const cartdata = cartData.getCart;
      const cartRows = cartdata.productIds.map(
        (productId: any, index: number) => {
          const productDetails = allproductsData.find(
            (product) => product.id === productId
          );

          if (productDetails && productDetails["Product Plan"]) {
            const planOptions = productDetails["Product Plan"].map(
              (plan: any) => ({
                label: plan.name,
                value: plan.name,
              })
            );

            newProductPlans[index] = planOptions;
          } else {
            newProductPlans[index] = [];
          }

          return {
            "Product Details": productDetails
              ? productDetails["Product Name"]
              : "",
            "Product Plan": "",
            "Billing Cycle": "",
            "HSN Number": productDetails ? productDetails["HSN Number"] : "",
            Quantity: 0,
            Rate: 0,
            Amount: 0,
            _productId: productId,
          };
        }
      );

      setRows([...cartRows, emptyRow]);
      setProductPlans(newProductPlans);
    } else {
      setRows([emptyRow]);
    }
  }, [cartData, allproductsData, setProductPlans]);

  useEffect(() => {
    cartRefetch({ customerId: userId });
  }, []);

  useEffect(() => {
    if (step) {
      setActiveStep(1);
    }
  }, [step]);

  useEffect(() => {
    if (data && data.getUserById) {
      const { basic_info, contact_info } = data.getUserById;

      setUserDetails({
        customerId: data.getUserById.user_id || "",
        customerName: basic_info.customer_fullname || "",
        email: basic_info.email || "",
        phoneNumber: basic_info.contact_number || "",
        address: contact_info.address
          ? `${contact_info.address}, ${contact_info.city}, ${contact_info.state}, ${contact_info.country} - ${contact_info.pincode}`
          : "",
        companyName: basic_info.company_name || "",
      });
    }
  }, [data]);

  const fetchProducts = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_PRODUCTS_AND_SERVICES,
        },
        config
      );
      const products = response.data.data.getAllProductsServices;
      const formattedProducts: any[] = products.map((product: any) => ({
        id: product.id || "",
        "Product Name": product.name || "",
        "Product Plan": product.plans.map((plan: any) => ({
          name: plan.name || "",
          pricing: plan.pricing.map((price: any) => ({
            currency: price.currency || "",
            tld: price.tld || "",
            rate: price.rate || 0,
            quarterly: price.quarterly || 0,
            semiAnnually: price.semiAnnually || 0,
            biAnnually: price.biAnnually || 0,
            annually: price.annually || 0,
            triAnnually: price.triAnnually || 0,
          })),
        })),
        Type: product.type || "",
        "HSN Number": product.hsnCode || "",
        "Module Name": product.module.provider || "",
        Module: {
          ...product.module,
        },
      }));
      setAllProductsData(formattedProducts);
    } catch (error: any) {
      setError(error.message);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const columns = [
    { field: "Product Details", headerName: "Product Name *" },
    { field: "Product Plan", headerName: "Product Plan *" },
    { field: "Billing Cycle", headerName: "Billing Cycle *" },
    { field: "HSN Number", headerName: "HSN Number *" },
    { field: "Quantity", headerName: "Quantity *" },
    { field: "Rate", headerName: "Rate *" },
  ];

  const handleInputChange = (
    index: number,
    fieldName: keyof (typeof rows)[0],
    value: any
  ) => {
    const newRows = [...rows];
    let hasError = false;

    const clearError = () => {
      setTableFieldErrors((prevErrors: { [key: string]: any }) => {
        const updatedErrors = { ...prevErrors };
        if (updatedErrors[index]) {
          delete updatedErrors[index][fieldName];
          if (Object.keys(updatedErrors[index]).length === 0) {
            delete updatedErrors[index];
          }
        }
        return updatedErrors;
      });
    };

    newRows[index] = {
      ...newRows[index],
      [fieldName]: value,
    };

    if (fieldName === "Product Details") {
      const selectedProduct = allproductsData.find(
        (product) => product["Product Name"] === value
      );

      if (selectedProduct) {
        newRows[index] = {
          ...newRows[index],
          "Product Details": value,
          "Product Plan": "",
          "Billing Cycle": "",
          Rate: 0,
          "HSN Number": selectedProduct["HSN Number"],
          _productId: selectedProduct.id,
        };

        setTableFieldErrors((prevErrors: { [key: string]: any }) => {
          const updatedErrors = { ...prevErrors };
          if (updatedErrors[index]) {
            delete updatedErrors[index]["Product Details"];
            delete updatedErrors[index]["HSN Number"];
            delete updatedErrors[index]["Tax"];
            delete updatedErrors[index]["Rate"];
            delete updatedErrors[index]["Amount"];
          }
          return updatedErrors;
        });
      } else {
        newRows[index] = {
          ...newRows[index],
          "Product Details": value,
          "HSN Number": "",
          Tax: "",
          Rate: 0,
          "Product Plan": "",
          Amount: 0,
        };
      }
    } else {
      if (fieldName === "Rate" || fieldName === "Quantity") {
        const rate = parseFloat(newRows[index].Rate) || 0;
        const quantity = parseFloat(newRows[index].Quantity) || 0;
        newRows[index].Amount = rate * quantity;

        if (
          /^\d*\.?\d+$/.test(newRows[index].Amount.toString()) ||
          newRows[index].Amount === 0
        ) {
          setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
            ...prevErrors,
            [index]: {
              ...prevErrors[index],
              ["Amount"]: "",
            },
          }));
        }
      }
    }
    if (fieldName === "Billing Cycle") {
      const selectedProduct = allproductsData.find(
        (product) =>
          product["Product Name"] === newRows[index]["Product Details"]
      );
      if (selectedProduct && selectedProduct["Product Plan"]) {
        const planName = newRows[index]["Product Plan"];
        const selectedPlan = selectedProduct["Product Plan"].find(
          (plan: any) => plan.name === planName
        );

        const mapCurrencyToCode = (displayCurrency: string): string => {
          const currencyMap: { [key: string]: string } = {
            INR: "INR",
            USD: "USD",
            EUR: "EUR",
          };
          return currencyMap[displayCurrency] || displayCurrency;
        };

        if (selectedPlan && selectedPlan.pricing) {
          const customerCurrency: any = mapCurrencyToCode(currency);
          const pricing = selectedPlan.pricing.find(
            (price: any) => price.currency === customerCurrency
          );

          if (pricing) {
            const billingCycleMap: { [key: string]: keyof typeof pricing } = {
              Quarterly: "quarterly",
              "Semi Annually": "semiAnnually",
              Annually: "annually",
              Biennially: "biAnnually",
              Triennially: "triAnnually",
            };

            const billingCycleKey = billingCycleMap[value];
            let rate = 0;

            if (billingCycleKey && billingCycleKey in pricing) {
              rate = pricing[billingCycleKey] as number;
            }

            newRows[index].Rate = rate;
            const quantity = parseFloat(newRows[index].Quantity) || 0;
            newRows[index].Amount = rate * quantity;
          }
        }
      }
    }

    if (fieldName === "Rate") {
      const isValidNumber = /^\d*\.?\d+$/.test(value) || value === "";
      if (isValidNumber) {
        clearError();
      } else {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.MRP_VAL,
          },
        }));
      }
    } else if (fieldName === "Quantity") {
      if (/^\d*$/.test(value) || value === "") {
        clearError();
      } else {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.QUANTITY_VAL,
          },
        }));
      }
    } else if (fieldName === "Amount") {
      const isValidNumber = /^\d*\.?\d+$/.test(value) || value === "";
      if (isValidNumber) {
        clearError();
      } else {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.AMT_VAL,
          },
        }));
      }
    } else if (fieldName === "HSN Number") {
      if (/^\d*$/.test(value) && value.length <= 8) {
        clearError();
      } else {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.HSN_VAL,
          },
        }));
      }
    }

    if (!hasError) {
      setRows(newRows);
    }
  };

  const steps: StepType[] = [
    {
      label: "Customer Details",
      content: <CustomerDetails userDetails={userDetails} />,
    },
    {
      label: "Product Details",
      content: (
        <ProductDetails
          rows={rows}
          setRows={setRows}
          columns={columns}
          handleInputChange={handleInputChange}
          currency={currency}
          setCurrency={setCurrency}
          fieldErrors={tableFieldErrors}
          setFieldErrors={setTableFieldErrors}
          formErrors={fieldErrors}
          setFormErrors={setFieldErrors}
          productsData={allproductsData}
          productPlans={productPlans}
          setProductPlans={setProductPlans}
        />
      ),
    },
    {
      label: "Payment",
      content: (
        <Payment
          rows={rows}
          setRows={setRows}
          orderDate={orderDate}
          setOrderDate={setOrderDate}
          formErrors={fieldErrors}
          setFormErrors={setFieldErrors}
          totalAmount={totalAmount}
          setTotalAmount={setTotalAmount}
        />
      ),
    },
  ];
  const validateAddProductsStep = () => {
    const newErrors: Record<string, string> = {};
    const newTableErrors: FieldErrors = {};
    let isValid = true;
    let hasValidRow = false;

    if (!currency) {
      newErrors["currency"] = ERROR_MESSAGES.CURRENCY_ERROR;
      isValid = false;
    }

    const nonEmptyRowCount = rows.filter(
      (row) =>
        row["Product Details"] ||
        row["Product Plan"] ||
        row["Billing Cycle"] ||
        row.Quantity
    ).length;

    const isSingleRow = rows.length === 1;

    rows.forEach((row, index) => {
      const hasAnyData =
        row["Product Details"] ||
        row["Product Plan"] ||
        row["Billing Cycle"] ||
        row.Quantity;

      if (isSingleRow || hasAnyData) {
        const rowErrors: { [fieldName: string]: string } = {};
        let rowIsValid = true;

        if (!row["Product Details"]) {
          rowErrors["Product Details"] = ERROR_MESSAGES.PDT_NAME;
          rowIsValid = false;
        }

        if (!row["Product Plan"]) {
          rowErrors["Product Plan"] = ERROR_MESSAGES.PLAN_REQ;
          rowIsValid = false;
        }

        if (!row["Billing Cycle"]) {
          rowErrors["Billing Cycle"] = ERROR_MESSAGES.BILLCYCLE_ERROR;
          rowIsValid = false;
        }

        if (!row.Quantity) {
          rowErrors["Quantity"] = ERROR_MESSAGES.QUAN_REQ;
          rowIsValid = false;
        }

        if (rowIsValid) {
          hasValidRow = true;
        }

        if (Object.keys(rowErrors).length > 0) {
          newTableErrors[index] = rowErrors;
          isValid = false;
        }
      }
    });

    if (!hasValidRow) {
      isValid = false;
      if (Object.keys(newTableErrors).length === 0) {
        newErrors["general"] =
          "At least one product with complete information is required";
      }
    }

    setFieldErrors(newErrors);
    setTableFieldErrors(newTableErrors);

    return isValid;
  };
  const handleNext = () => {
    const isValid = activeStep === 1 ? validateAddProductsStep() : true;
    if (isValid) {
      setFieldErrors({});
      setTableFieldErrors({});
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/solutions");
    } else {
      setActiveStep((prev) => prev - 1);
    }
  };

  const mapRowsToProductOrService = (rows: RowData[]): any[] => {
    const nonEmptyRows = rows.filter((row) => {
      return (
        row["Product Details"] ||
        row["Tax"] ||
        row["Amount"] ||
        row["Rate"] ||
        row["Quantity"] ||
        row["Product Plan"] ||
        row["Billing Cycle"] ||
        row["HSN Number"] ||
        row["_productId"]
      );
    });

    return nonEmptyRows.map((row) => ({
      name: row["Product Details"] || null,
      tax: row["Tax"] || null,
      amount: row["Amount"] ? Number(row["Amount"]) : null,
      rate: row["Rate"] ? Number(row["Rate"]) : null,
      quantity: row["Quantity"] ? Number(row["Quantity"]) : null,
      productplan: row["Product Plan"] || null,
      billcycle: row["Billing Cycle"] || null,
      hsnCode: row["HSN Number"] || null,
      productId: row["_productId"] || null,
    }));
  };

  const addDays = (dateStr: any, days: any) => {
    const [day, month, year] = dateStr.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    date.setDate(date.getDate() + days);
    const dueDate = `${("0" + date.getDate()).slice(-2)}/${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}/${date.getFullYear()}`;
    return dueDate;
  };

  function formatOrderDate(orderdate: any) {
    let date = new Date(orderdate.$d);
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const handleSaveAndExit = async () => {
    if (!orderDate) {
      setFieldErrors({
        ...fieldErrors,
        orderDate: "Please select a date",
      });
      return;
    }
    let order = formatOrderDate(orderDate);

    const productOrService = mapRowsToProductOrService(rows);
    const userId = localStorage.getItem("userId");
    const variables = {
      user_id: userId,
      invoicedate: order,
      duedate: addDays(order, 30),
      productOrService: productOrService,
      price: totalAmount?.toString(),
      status: "Unpaid",
      name: userDetails.customerName,
      customer_id: userDetails.customerId,
      phone_no: userDetails.phoneNumber,
      email: userDetails.email,
      currency: currency,
      quote_status: false,
      order_status: true,
    };

    try {
      const { data, errors } = await addInvoiceMutation({ variables });
      if (data.addInvoice && data.addInvoice.id) {
        const cartProductIds = cartData?.getCart?.productIds || [];
        const invoiceProductIds = productOrService.map(
          (item) => item.productId
        );

        const productsToRemove = cartProductIds.filter((productId: any) =>
          invoiceProductIds.includes(productId)
        );
        for (const productId of productsToRemove) {
          await removeFromCart({
            variables: { customerId: userId, productId },
          });
        }
        setSnackbarMessage(
          `${data.addInvoice.invoiceid} - ${TEXT_MESSAGES.INVOICE_SUCCESS}`
        );
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          setOpen(true);
        }, 3000);
      }
    } catch (error) {
      console.error("ApolloError:", error);
      if (error instanceof ApolloError) {
        const errorMessage = error.graphQLErrors?.[0]?.message || error.message;
        setSuccMessage(errorMessage);
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    }
   
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        mx: "auto",
        padding: "15px",
      }}
    >
      <Box sx={{ display: "flex", gap: 0, width: "100%" }}>
        <Box sx={{ minWidth: 200 }}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={{
              maxHeight: "210px",
              pt: 1,
              "& .MuiStepLabel-root": {
                "& .MuiStepLabel-label": {
                  color: "rgba(0, 0, 0, 0.86)", 
                  "&.Mui-active": {
                    color: "#181D8C",
                  },
                },
              },
              "& .MuiStepConnector-line": {
                borderColor: "#7A80FF",
                borderLeftWidth: 2,
                marginLeft: "12px",
              },
            }}
          >
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  StepIconComponent={CustomStepIcon}
                  sx={{
                    backgroundColor:
                      index === activeStep ? "#FBF7FF" : "#F4F3F5",
                    padding: "10px",
                    color:
                    index === activeStep
                      ? "#181D8C"
                      : "rgba(0, 0, 0, 0.86)",
                    borderRadius: "8px",
                    "& .MuiStepLabel-root": {
                      backgroundColor:
                        index < activeStep ? "rgba(0,0,0,0.08)" : "transparent",
                      borderRadius: "0px",
                      padding: 1,
                      color:
                        index === activeStep
                          ? "#181D8C"
                          : "rgba(0, 0, 0, 0.86)",
                      fontSize: "14px",
                    },
                  }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box sx={{ flex: 3 }}>{steps[activeStep].content}</Box>
      </Box>

      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "space-between",
          width: "96%",
          borderTop: "1px solid #CBC2E9",
          pt: 2,
          marginRight: "1%",
        }}
      >
        <Button
          onClick={() => navigate("/solutions")}
          sx={{
            textTransform: "none",
            color: "#181D8C",
            border: "1px solid #181D8C",
            width: 125,
            height: 36,
            borderRadius: "5px",
            ml: "2%",
          }}
        >
          {TEXT_MESSAGES.CANCEL}
        </Button>
        <Box sx={{ display: "flex", gap: 2, mr: "2%" }}>
          {activeStep !== 0 && (
            <Button
              onClick={handleBack}
              sx={{
                textTransform: "none",
                color: "white",
                bgcolor: "#181D8C",
                width: 125,
                height: 36,
                borderRadius: "5px",
                "&:hover": { bgcolor: "#181D8C" },
              }}
            >
              {TEXT_MESSAGES.BACK}
            </Button>
          )}
          <Button
            onClick={
              activeStep === steps.length - 1 ? handleSaveAndExit : handleNext
            }
            sx={{
              textTransform: "none",
              color: "white",
              bgcolor: "#181D8C",
              width: 125,
              height: 36,
              borderRadius: "5px",
              "&:hover": { bgcolor: "#181D8C" },
            }}
          >
            {activeStep === steps.length - 1
              ? isEditMode
                ? TEXT_MESSAGES.UPDATE_TXT
                : TEXT_MESSAGES.PAY_TEXT
              : TEXT_MESSAGES.NEXT}
          </Button>
        </Box>
      </Box>
      <SnackBar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
        vertical="top"
        horizontal="right"
        customAction={
          <IconButton
            size="small"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ color: "#181D8C" }}>
          {TEXT_MESSAGES.PAYMENTNOTE}
        </DialogTitle>
        <DialogContent>{TEXT_MESSAGES.CONTACTADMIN}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {setOpen(false); navigate('/solutions')}}
            sx={{
              textTransform: "none",
              color: "white",
              bgcolor: "#181D8C",
              borderRadius: "5px",
              "&:hover": { bgcolor: "#181D8C" },
            }}
          >
            {TEXT_MESSAGES.OK}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PheonixStepper;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Plancard from "./ProductCard";
import { Typography, Grid, Paper, Box, Button } from "@mui/material";
import { TEXT_MESSAGES } from "../const";
import PheonixBreadcrumbs from "../Components/PheonixBreadcrumbs";

const ViewProduct: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [newBreadcrumbText, setNewBreadcrumbText] = useState("");
  const breadcrumbText = location.state?.breadcrumbText || "";
  const productService = (() => {
    if (!location.state) return null;
    if (location.state.product !== undefined) return location.state.product;
    if (location.state.service !== undefined) return location.state.service;
    if (location.state.rowData !== undefined) return location.state.rowData;
    return null;
  })();
  const productDetails = (() => {
    if (
      location.state.rowData !== undefined &&
      location.state.rowData.productDetails
    )
      return location.state.rowData.productDetails;
     
    return null;
  })();
  const handleBack = () => {
    navigate("/productgridview");
  };

  return (
    <div style={{ maxHeight: "90vh", overflowY: "auto", fontFamily: "inter" }}>
      <h1
        style={{
          fontSize: "22px",
          fontWeight: 700,
          color: "#181D8C",
          marginLeft: "20px",
        }}
      >
        {TEXT_MESSAGES.PRODORSERVICE}
      </h1>
      <div style={{ marginLeft: "20px" }}>
        <PheonixBreadcrumbs
          breadcrumbText={newBreadcrumbText || breadcrumbText}
        />
      </div>
      <Paper elevation={3} style={{ margin: "20px", padding: "20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            {[
              {
                title: "Product Name",
                content: productDetails?.name || productService?.name,
              },
              (productService?.description || productDetails?.description) && {
                title: "Product Overview",
                content:
                  productDetails?.description || productService?.description,
              },
              (productService?.benefit || productDetails?.benefit) && {
                title: "Product Features",
                content: productDetails?.benefit || productService?.benefit,
              },
            ]

              .filter(Boolean)
              .map(({ title, content }, index) => (
                <React.Fragment key={index}>
                  <Box sx={{ marginTop: index === 0 ? "0" : "20px" }}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontFamily: "inter",
                        fontSize: "16px",
                      }}
                    >
                      {title}
                    </Typography>
                  </Box>
                  {content && (
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontFamily: "inter",
                        fontSize: "16px",
                        marginTop: "10px",
                      }}
                    >
                      {content}
                    </Typography>
                  )}
                </React.Fragment>
              ))}
          </Box>
          {productService.url && (
          <Box sx={{ marginLeft: "20px" }}>
            <Button
                   onClick={() =>
                     window.open(productService.url, "_blank")
                  }
              sx={{
                color: "#181D8C",
                border: "1px solid #181D8C",
                backgroundColor: "#E7E9FF",
                height: "35px",
                width:"200px",
                borderRadius: "5px",
                textTransform: "none",
                "&:hover": { backgroundColor: "#181D8C", color:"white" },
                "&:active": { backgroundColor: "#181D8C", color:"white" },
              }}
            >
             
               {TEXT_MESSAGES.TAKETOSITE}
            </Button>
          </Box>)}
        </Box>
        {(productService?.module &&
          Object.keys(productService.module).length > 0) ||
        (productDetails?.module &&
          Object.keys(productDetails.module).length > 0) ? (
          <>
            <Typography
              style={{
                fontWeight: 600,
                marginTop: "20px",
                fontFamily: "inter",
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.15px",
              }}
            >
              {TEXT_MESSAGES.APPLICATION_TEXT}
            </Typography>
            <div style={{ marginTop: "10px", overflowX: "auto" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        fontFamily: "inter",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                        fontWeight: 400,
                        color: "#000000DB",
                      }}
                    >
                      Module Name
                    </th>
                    {Object.entries(
                      productService?.module || productDetails?.module
                    )
                      .filter(
                        ([key, value]) =>
                          key !== "provider" &&
                          key !== "ModuleName" &&
                          key !== "providerType" &&
                          key !== "remarks" &&
                          (typeof value === "string"
                            ? value.trim() !== ""
                            : value !== null && value !== undefined)
                      )
                      .map(([key], index) => (
                        <th
                          key={index}
                          style={{
                            padding: "8px",
                            textAlign: "left",
                            fontFamily: "inter",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            fontWeight: 400,
                            color: "#000000DB",
                          }}
                        >
                          {key}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "8px",
                        fontFamily: "inter",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                        fontWeight: 600,
                        color: "#181D8C",
                      }}
                    >
                      {productService?.module?.provider ||
                        productService?.module?.ModuleName ||
                        productDetails?.module?.provider ||
                        productDetails?.module?.ModuleName ||
                        ""}
                    </td>
                    {Object.entries(
                      productService?.module || productDetails?.module
                    )
                      .filter(
                        ([key, value]) =>
                          key !== "provider" &&
                          key !== "ModuleName" &&
                          key !== "providerType" &&
                          key !== "remarks" &&
                          (typeof value === "string"
                            ? value.trim() !== ""
                            : value !== null && value !== undefined)
                      )
                      .map(([key, value], index) => (
                        <td
                          key={index}
                          style={{
                            padding: "8px",
                            fontFamily: "inter",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                            fontWeight: 600,
                            color: "#181D8C",
                          }}
                        >
                          {typeof value === "string"
                            ? value
                            : JSON.stringify(value)}
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : null}

        {productService?.remarks ||
          (productDetails?.remarks && (
            <>
              <Typography
                style={{
                  fontWeight: 600,
                  marginTop: "20px",
                  fontFamily: "inter",
                  fontSize: "16px",
                }}
              >
                {TEXT_MESSAGES.REMARKS}
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontFamily: "inter",
                  fontSize: "16px",
                  marginTop: "10px",
                }}
              >
                {productDetails?.remarks || productService?.remarks}
              </Typography>
            </>
          ))}
        <div
          style={{
            backgroundColor: "#EFEFFF",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <Grid container spacing={4}>
            {(productDetails?.plans || productService?.plans || []).map(
              (plan: any, index: number) => (
                <Grid item key={index}>
                  <Plancard plan={plan} />
                </Grid>
              )
            )}
          </Grid>          
        </div>
        <Box sx={{ display:"flex", alignItems:"flex-end", justifyContent:"flex-end", mt:2 }}>
            <Button
                sx={{
                color: "white",
                border: "1px solid",
                backgroundColor: "#181D8C",
                height: "35px",
                width:"80px",
                borderRadius: "5px",
                textTransform: "none",
                "&:hover": { backgroundColor: "#E7E9FF", color:"#181D8C" },
                "&:active": { backgroundColor: "#E7E9FF", color:"#181D8C" },
              }}
              onClick={() => handleBack()} 

            >
               {TEXT_MESSAGES.BACK}
            </Button>
          </Box>
      </Paper>
    </div>
  );
};

export default ViewProduct;

import { gql } from "@apollo/client";
export const ADD_INVOICE_MUTATION = gql`
  mutation AddInvoice(
    $user_id: ID!
    $invoiceid: String
    $name: String
    $invoicedate: String
    $quotationid: String
    $quotationNo: String
    $productOrService: [ProductOrServiceInput]
    $price: String
    $paid_amount: String
    $due_amount: String
    $duedate: String
    $status: String
    $customer_id: String!
    $phone_no: String
    $email: String
    $currency: String
    $discount: Int
    $discountType: String
    $taxamount: Float
    $customernotes: String
    $terms_conditions: String
    $order_status: Boolean
    $quote_status: Boolean
    $customerNo: String
  ) {
    addInvoice(
      user_id: $user_id
      invoiceid: $invoiceid
      name: $name
      invoicedate: $invoicedate
      quotationid: $quotationid
      quotationNo: $quotationNo
      productOrService: $productOrService
      price: $price
      paid_amount: $paid_amount
      due_amount: $due_amount
      duedate: $duedate
      status: $status
      customer_id: $customer_id
      phone_no: $phone_no
      email: $email
      currency: $currency
      discount: $discount
      discountType: $discountType
      taxamount: $taxamount
      customernotes: $customernotes
      terms_conditions: $terms_conditions
      order_status: $order_status
      quote_status: $quote_status
      customerNo: $customerNo
    ) {
      id
      serial_no
      user_id
      name
      invoiceid
      quotationid
      quotationNo
      orderid
      order_no
      invoicedate
      price
      paid_amount
      due_amount
      duedate
      status
      paymentdate
      bal_amount
      invoice_no
      customer_id
      phone_no
      email
      currency
      discount
      discountType
      taxamount
      customernotes
      terms_conditions
      order_status
      quote_status
      customerNo
      productOrService {
        name
        hsnCode
        productplan
        billcycle
        tax
        amount
        rate
        quantity
        price
      }
    }
  }
`;
export const EDIT_INVOICE_MUTATION = `
  mutation EditInvoice($user_id: ID!,$_id: ID!, $status: String!, $productOrService: [ProductOrServiceInput!]!,$price: String!,$paid_amount: String!, 
    $duedate: String!, $expiry_date: String!, $gstType: String!, $email: String!, $phone_no: String, $customer_name: String!,$customer_id: String!,$description: String!) {
    editInvoice(user_id: $user_id,_id: $_id, status: $status, productOrService: $productOrService,duedate: $duedate,price : $price,paid_amount: $paid_amount, expiry_date: $expiry_date,
      gstType: $gstType,email: $email, phone_no: $phone_no, customer_name: $customer_name, customer_id: $customer_id,description:$description) {
      id
      serial_no
      user_id
      invoiceid
      invoicedate
      productOrService {
        name
        tax
        amount
        rate
        quantity
      }
      price
      paid_amount
      description
      duedate
      status
      expiry_date
      invoice_no
      customer_name
       customer_id
      gstType
      phone_no
      email
    }
  }
`;
export const ADD_CUSTOMER_MUTATION = `
  mutation AddUser(
    $user_id: ID, 
    $company_name: String, 
    $phone_number: String,
    $email: String!,
    $firstname: String,
    $GST_no: String, 
    $address_line1: String,
    $city: String,
    $pincode: String,
    $state: String,
    $country: String,
    $mail_status:Boolean,
  ) {
    addUser(
      user_id: $user_id, 
      company_name: $company_name, 
      phone_number: $phone_number,
      email: $email,
      firstname: $firstname, 
      GST_no: $GST_no, 
      address_line1: $address_line1,
      city: $city,
      pincode: $pincode,
      state: $state,
      country: $country,
      mail_status:$mail_status,
    ) {
      success
      message
      data {
        _id
        user_id
        password
        customer_no
        confirmationToken
        confirmed
        mail_status
        passwordChangeOtp
        user_type
        active_status
        basic_info {
            firstname
            lastname
            email
            phone_number
            customer_type
            company_name
            shipping_address {
                address_line1
                city
                state
                pincode
                country
            }
        }
        contact_info {
            contact_name
            contact_no
            contact_email
        }
        billing_info {
            GST_type
            GST_no
            payment_terms
            currency
            business_name
            billing_address {
                address_line1
                city
                state
                pincode
                country
            }
        }
        portal_info {
            email
            password
        }
    }
 }
  }
`;

export const EDIT_CUSTOMER_MUTATION = `
  mutation EditUser(
    $_id: ID!,
    $user_id: ID!, 
    $company_name: String, 
    $phone_number: String,
    $email: String!,
    $firstname: String,
    $GST_no: String!, 
    $address_line1: String,
    $city: String,
    $pincode: String,
    $state: String,
    $country: String,
    $mail_status:Boolean,
    $passwordCheck:Boolean,
  ) {
    editUser(
      _id:$_id,
       user_id: $user_id, 
      company_name: $company_name, 
      phone_number: $phone_number,
      email: $email,
      firstname: $firstname, 
      GST_no: $GST_no, 
      address_line1: $address_line1,
      city: $city,
      pincode: $pincode,
      state: $state,
      country: $country,
      mail_status:$mail_status,
      passwordCheck:$passwordCheck,
    ) {
      success
      message
      data {
        _id
        user_id
        password
        customer_no
        confirmationToken
        confirmed
        mail_status
        passwordChangeOtp
        user_type
        active_status
        basic_info {
            firstname
            lastname
            email
            phone_number
            customer_type
            company_name
            shipping_address {
                address_line1
                city
                state
                pincode
                country
            }
        }
        contact_info {
            contact_name
            contact_no
            contact_email
        }
        billing_info {
            GST_type
            GST_no
            payment_terms
            currency
            business_name
            billing_address {
                address_line1
                city
                state
                pincode
                country
            }
        }
        portal_info {
            email
            password
        }
    }
  }
}
`;

export const DELETE_CUSTOMER = `
  mutation DeleteUserById($id: ID!) {
    deleteUserById(id: $id) {
        success
        message
    }
  }
`;
export const DELETE_INVOICE = `
  mutation DeleteInvoice($id: ID!) {
    deleteInvoice(_id: $id) {
        success
        message
    }
  }
`;
export const DELETE_ORDER = `
  mutation DeleteOrder($id: ID!) {
    deleteOrder(_id: $id) {
        success
        message
    }
  }
`;
export const DELETE_QUOTATION = `
  mutation DeleteQuotation($id: ID!) {
    deleteQuotation(_id: $id) {
        success
        message
    }
  }
`;
export const UPDATE_STATUS = `
  mutation UpdateStatus($id: ID!,$active_status: Boolean) {
    updateStatus(id: $id,active_status: $active_status) {
        success
        message
    }
  }
`;

export const EDIT_ORDER_MUTATION = `
  mutation EditOrder(
    $_id: ID!,
    $user_id: ID!, 
    $company_name: String!, 
    $customer_name: String!, 
    $customerId: String!,
    $contact_no: String!, 
    $contact_email: String!, 
    $productOrService: [String]!, 
    $plan: String!,
    $order_details: String!
    $renewal: String
    $actual_amount: String
    $proposed_amount: String
  ) {
    editOrder(
      _id:$_id,
      user_id: $user_id, 
      company_name: $company_name, 
      customer_name: $customer_name, 
      customerId: $customerId,
      contact_no: $contact_no, 
      contact_email: $contact_email, 
      productOrService: $productOrService, 
      plan: $plan,
      order_details: $order_details,
      renewal:$renewal
      actual_amount:$actual_amount
      proposed_amount:$proposed_amount
    ) {
            id
            serial_no
            user_id
            name
            orderid
            status
            prodstatus
            price
            renewal
            orderdate
            order_no
            company_name
            customer_name
            customerId
            contact_no
            contact_email
            productOrService_id
            productOrService
            plan_id
            plan
            order_details
            proposed_amount
            actual_amount
 }
  }
`;
export const EXITED_CUSTOMER_ADDED_MUTATION = gql`
  mutation ExistingUserAdded(
    $_id: ID!
    $user_id: ID!
    $company_name: String
    $phone_number: String
    $email: String!
    $firstname: String
    $GST_no: String
    $address_line1: String
    $city: String
    $pincode: String
    $state: String
    $country: String
    $mail_status: Boolean
  ) {
    existingUserAdded(
      _id: $_id
      user_id: $user_id
      company_name: $company_name
      phone_number: $phone_number
      email: $email
      firstname: $firstname
      GST_no: $GST_no
      address_line1: $address_line1
      city: $city
      pincode: $pincode
      state: $state
      country: $country
      mail_status: $mail_status
    ) {
      success
      message
      data {
        _id
        user_id
        password
        customer_no
        confirmationToken
        confirmed
        mail_status
        passwordChangeOtp
        user_type
        active_status
        basic_info {
          firstname
          lastname
          email
          phone_number
          customer_type
          company_name
          shipping_address {
            address_line1
            city
            state
            pincode
            country
          }
        }
        contact_info {
          contact_name
          contact_no
          contact_email
        }
        billing_info {
          GST_type
          GST_no
          payment_terms
          currency
          business_name
          billing_address {
            address_line1
            city
            state
            pincode
            country
          }
        }
        portal_info {
          email
          password
        }
      }
    }
  }
`;
export const UPDATEUSER = gql`
  mutation UpdateUser(
    $id: ID!
    $basic_info: BasicNewInfoInput
    $contact_info: ContactNewInfoInput
    $billing_info: BillingNewInfoInput
  ) {
    updateUser(
      id: $id
      basic_info: $basic_info
      contact_info: $contact_info
      billing_info: $billing_info
    ) {
      success
      message
      userId
    }
  }
`;
export const ADD_QUOTATIION_REQUEST = gql`
  mutation AddQuotation(
    $user_id: ID!
    $name: String
    $quotationdate: String
    $duedate: String
    $productOrService: [ProductOrServiceInput]
    $customernotes: String
    $customerName: String
    $customerId: String!
    $customerNo: String
    $phoneNumber: String
    $email: String
    $address: String
    $status: String!
  ) {
    addQuotation(
      user_id: $user_id
      name: $name
      quotationdate: $quotationdate
      duedate: $duedate
      productOrService: $productOrService
      customernotes: $customernotes
      customerName: $customerName
      customerNo: $customerNo
      customerId: $customerId
      phoneNumber: $phoneNumber
      email: $email
      address: $address
      status: $status
    ) {
      id
      serial_no
      user_id
      name
      quotationid
      quotationdate
      productOrService {
        name
        tax
        amount
        rate
        quantity
      }
      duedate
      customernotes
      quotationnotes
      currency
      customerName
      customerNo
      customerId
      phoneNumber
      email
      address
      terms_conditions
      price
      status
    }
  }
`;
export const ADD_TO_CART = gql`
  mutation AddToCart($customerId: ID!, $productId: ID!) {
    addToCart(customerId: $customerId, productId: $productId) {
      success
      message
      cart {
        id
        customerId
        productIds
        createdAt
      }
    }
  }
`;

export const REMOVE_FROM_CART = gql`
  mutation RemoveFromCart($customerId: ID!, $productId: ID!) {
    removeFromCart(customerId: $customerId, productId: $productId) {
      success
      message
      cart {
        id
        customerId
        productIds
        createdAt
      }
    }
  }
`;

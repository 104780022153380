import React, {useEffect, useState} from "react";
import {
  Typography,
  IconButton,
  Card,
  CardContent,
  Box,
  List,
  ListItem,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { TEXT_MESSAGES } from "../const";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface Plan {
  name: string;
  pricing?: any[];
  billingcycle?: string;
  features?: string[];
}

const PlanCard: React.FC<{
  plan: any;
  onEdit?: () => void;
  billcycle?: string;
  rate?: number;
  rowData?: any;
  isSelected?: boolean;
  currencySymbol?: string;
  onSelect?: () => void;
}> = ({
  plan,
  onEdit,
  billcycle,
  rate,
  rowData,
  onSelect,
  isSelected,
  currencySymbol,
}) => {
  const pricingData = Array.isArray(plan.pricing)
    ? plan.pricing[0]
    : plan.pricing;

    const defaultRate =
    billcycle && pricingData
      ? pricingData[
          Object.keys(pricingData).find(
            (key) => key.toLowerCase() === billcycle.toLowerCase()
          ) || ""
        ]
      : undefined;
      const [displayedRate, setDisplayedRate] = useState(rate || defaultRate);

      useEffect(() => {
        if (!rate) {
          setDisplayedRate(defaultRate);
        }
      }, []);

  return (
    <Card
      sx={{
        width: 240,
        maxHeight: 215,
        height:"auto",
        pb: 2,
        display: "flex",
        boxShadow: 1,
        borderRadius: "8px",
        lineHeight: "20px",
        border: isSelected ? "1px solid #181D8C" : "none",
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ fontFamily: "inter", fontSize: "18px", fontWeight: 600 }}
            >
              {plan.name}
            </Typography>
            {billcycle && (
              <Typography
                sx={{
                  fontFamily: "inter",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#181D8C",
                }}
              >
                {billcycle}
              </Typography>
            )}
             {displayedRate !== undefined && (
              <Typography
                sx={{
                  fontFamily: "inter",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#181D8C",
                }}
              >
                {currencySymbol}
                {displayedRate}
              </Typography>
             )}
          </Box>
          {onEdit && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "28px",
                height: "28px",
                borderRadius: "8px",
                backgroundColor: "#181D8C",
              }}
            >
              <IconButton sx={{ color: "#ffffff" }} onClick={onEdit}>
                <EditIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Box>
          )}
        </Box>
       {rowData && ( <FormControlLabel
          control={<Radio checked={isSelected} onChange={onSelect} />}
          label="Choose a plan to Upgrade/Downgrade"
          sx={{
            mt: 1,
            fontSize: "0.5rem",
            color: "#000",
            fontWeight: 400,
          }}
        />)}
        {plan.features &&
          plan.features.filter((f: any) => f.trim() !== "").length > 0 && (
            <>
              <Typography
                sx={{
                  fontFamily: "inter",
                  fontSize: "14px",
                  fontWeight: 600,
                  mt: 1,
                }}
              >
                {TEXT_MESSAGES.FEATURES}
              </Typography>
              <List
                sx={{
                  maxHeight: "100px",
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                  "&::-webkit-scrollbar": { width: "4px" },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#181D8C",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-track": { background: "transparent" },
                }}
              >
                {plan.features.map((feature: any, index: any) => (
                  <ListItem
                    key={index}
                    sx={{ display: "flex", alignItems: "center", p: 0.5 }}
                  >
                    <CheckCircleIcon
                      sx={{
                        color: "#05CD31",
                        fontSize: 16,
                        mr: 1,
                        mt: "2px",
                        width: "12px",
                        height: "12px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "inter",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      {feature}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </>
          )}
      </CardContent>
    </Card>
  );
};

export default PlanCard;

import { Typography, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Card, CardContent, CardActions } from "@mui/material";
import { Button } from "@mui/material";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import Slider from "react-slick";
import { Box, Grid } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import PheonixBreadcrumbs from "../Components/PheonixBreadcrumbs";
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  PaddingTwoTone,
} from "@mui/icons-material";
import ProdImage from "../assets/viewpdt.png";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GET_PRODUCTS_BY_CUSTOMER,
  GET_ALL_PRODUCTS_FOR_CUSTOMER,
} from "../graphql/query";
import PaymentDetailsModal from "./PaymentDetailsModal";
import { TEXT_MESSAGES } from "../const";

interface Product {
  name: string;
  icon: string;
  plan: string;
  validity: string;
  details: string[];
  paymentDue: boolean;
}
interface OrderProduct {
  order_id: String;
  orderid: String;
  duedate: String;
  name: String;
  hsnCode: String;
  productplan: String;
  billcycle: String;
  tax: String;
  amount: Number;
  rate: Number;
  quantity: Number;
  productId: String;
  productDetails: Product;
  promocodes: String[];
}

const columns = [
  {
    field: "productDetails.status",
    headerName: "Status",
    flex: 2,
    minWidth: 175,
    valueGetter: (params: any) => {
      return params.row.productDetails?.status ? "Active" : "Inactive";
    },
  },
  {
    field: "invoiceno",
    headerName: "Invoice ID",
    width: 225,
  },
  {
    field: "duedate",
    headerName: "Renewal Date",
    width: 275,
    valueGetter: (params: any) => params.row.duedate || "N/A",
  },

  {
    field: "productDetails.name",
    headerName: "Product / Service",
    width: 175,
    valueGetter: (params: any) => params.row.productDetails?.name || "N/A",
  },
];

const CustomPrevArrow: React.FC<{
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      left: 0,
      top: "50%",
      color: "#181D8C",
      padding: 0,
      border: 3,
      borderColor: "#181D8C",
      transform: "translateY(-50%)",
      zIndex: 10,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      boxShadow: 2,
      "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
    }}
  >
    <ChevronLeftRounded />
  </IconButton>
);

const CustomNextArrow: React.FC<{
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 10,
      padding: 0,
      color: "#181D8C",
      border: 3,
      borderColor: "#181D8C",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      boxShadow: 2,
      "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
    }}
  >
    <ChevronRightRounded />
  </IconButton>
);

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
};
const verticalSliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  vertical: true,
};
const ServicesCard: React.FC<{ service: any }> = ({ service }) => (
  <Box
    p={1}
    borderRadius="16px"
    bgcolor="#E9E9E9"
    textAlign="left"
    boxShadow={1}
    mx="auto"
    maxWidth={213}
    sx={{ marginBottom: "7px", marginTop: "5px" }}
  >
    <Typography fontWeight={600} sx={{ color: "#181D8C", fontSize: "12px" }}>
      {service.Order.productDetails.name}
    </Typography>
    {service.Order.productDetails.image && (
      <Box
        component="img"
        src={service.Order.productDetails.image}
        sx={{
          width: "25px",
          height: "25px",
          marginRight: "8px",
          objectFit: "cover",
        }}
        alt={service.Order.productDetails.name || "Service Image"}
      />
    )}
  </Box>
);
const ProductCard: React.FC<{ product: any }> = ({ product }) => {
  const [open, setOpen] = useState(false);
  const formatDate = (dateString: any) => {
    const [day, month, year] = dateString.split("/");

    const date = new Date(year, month - 1, day);

    return date
      .toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(/(\w+)\s(\w+)\s(\d+)/, "$1 $2 $3");
  };

  return (
    <Card
      sx={{
        p: 1,
        mx: "auto",
        borderRadius: 2,
        boxShadow: 2,
        width: 356,
        maxHeight: 270,
        overflowY: "auto",
      }}
    >
      <CardContent>
        <Typography
          fontSize={14}
          fontWeight={600}
          color="#181D8C"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {product.Order.productDetails.image && (
            <Box
              component="img"
              src={product.Order.productDetails.image}
              sx={{
                width: "25px",
                height: "25px",
                marginRight: "8px",
                objectFit: "cover",
              }}
              alt={product.Order.productDetails.name || "Product Image"}
            />
          )}
          {product.Order.productDetails?.name}
        </Typography>

        <Box display="flex" justifyContent={"space-between"} gap={1}>
          <Button
            variant="contained"
            size="small"
            sx={{
              fontSize: "12px",
              color: "white",
              backgroundColor: "#7A80FF",
              borderRadius: "8px",
              mt: 2,
            }}
          >
            {product.Order.productplan}
          </Button>
          <Typography
            sx={{ fontSize: "12px", color: "black", fontWeight: 400, mt: 2 }}
          >
            Validity: {formatDate(product.Order.duedate)}
          </Typography>
        </Box>
        <ul>
          {Array.isArray(product.Order.productDetails?.benefit) ? (
            product.Order.productDetails.benefit.map((detail: any, i: any) => (
              <li key={i}>
                <Typography
                  sx={{ fontSize: "12px", color: "black", fontWeight: 400 }}
                >
                  {detail}
                </Typography>
              </li>
            ))
          ) : (
            <li>
              <Typography
                sx={{ fontSize: "12px", color: "black", fontWeight: 400 }}
              >
                No benefits available
              </Typography>
            </li>
          )}
        </ul>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          sx={{
            color: "#181D8C",
            textTransform: "capitalize",
            border: "none",
            fontWeight: 400,
            fontSize: "12px",
            pt: 0,
          }}
          onClick={() => setOpen(true)}
        >
          View Payment Details
        </Button>
        { (
          <Button
            sx={{
              fontSize: "12px",
              color: product.Order?.status?.toLowerCase() === "overdue" ? "#8C1818" :'white',
              textTransform: "capitalize",
              borderRadius: "8px",
              fontWeight: 400,
              lineHeight: "normal",
              backgroundColor: product.Order?.status?.toLowerCase() === "overdue" ? "#FFD9D7" : 'white',
            }}
          >
            Payment Overdue
          </Button>
        )}
      </CardActions>
      <PaymentDetailsModal
        open={open}
        product={product.Order}
        onClose={() => setOpen(false)}
      />
    </Card>
  );
};
const Products = () => {
  const [breadcrumbText, setBreadcrumbText] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [productServiceData, setProductServiceData] = useState<OrderProduct[]>(
    []
  );
  const [allProductsData, setAllProductsData] = useState<any[]>([]);
  const [servicesData, setServicesData] = useState<any[]>([]);
  const [productsData, setProductsData] = useState<any[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
    if (newQuery.trim() === "") {
      productRefetch({ variables: { searchQuery: {} } });
    } else {
      productRefetch();
    }
  };
  const navigate = useNavigate();
  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const handleView = () => {
    navigate("/productgridview", {state: { breadcrumbText: `All Products and Services`}});
  };

  const userId = localStorage.getItem("userId");
  const {
    loading: productLoading,
    error: productError,
    data: productData,
    refetch: productRefetch,
  } = useQuery(GET_PRODUCTS_BY_CUSTOMER, {
    variables: {
      customerId: userId,
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      searchQuery: searchQuery,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const { totalCount, productandservices } = data.getProductsByCustomer;
      const responseDataWithIndex =
        data.getProductsByCustomer.productandservices.map(
          (item: any, index: number) => ({
            ...item,
            id: `${item.order_id}_${index}`,
          })
        );
      setProductServiceData(responseDataWithIndex);
      setTotalPages(totalCount);
    },
  });

  useEffect(() => {
    if (!productLoading && !productError && productData) {
      const { totalCount, productandservices } =
        productData.getProductsByCustomer;
      const responseDataWithIndex =
        productData.getProductsByCustomer.productandservices.map(
          (item: any, index: number) => ({
            ...item,
            id: `${item.order_id}_${index}`,
          })
        );
      setProductServiceData(responseDataWithIndex);
      setTotalPages(totalCount);
    }
  }, [productLoading, productData, productError, searchQuery]);

  const {
    loading: productTypeLoading,
    error: productTypeError,
    data: productTypeData,
    refetch: productTypeRefetch,
  } = useQuery(GET_ALL_PRODUCTS_FOR_CUSTOMER, {
    variables: {
      customerId: userId,
    },
    onCompleted: (data) => {
      const allProducts = data.getAllProductsForCustomer;

      const services = allProducts.filter(
        (product: any) => product.Order.productDetails.type === "Services"
      );

      const products = allProducts.filter(
        (product: any) => product.Order.productDetails.type === "Product"
      );

      setServicesData(services);
      setProductsData(products);
      setAllProductsData(allProducts);
    },
  });
  useEffect(() => {
    productRefetch({
      customerId: userId,
    });
  }, []);

  useEffect(() => {
    if (!productTypeLoading && !productTypeError && productTypeData) {
      const allProducts = productTypeData.getAllProductsForCustomer;

      const services = allProducts.filter(
        (product: any) => product.Order.productDetails.type === "Services"
      );

      const products = allProducts.filter(
        (product: any) => product.Order.productDetails.type === "Product"
      );

      setServicesData(services);
      setProductsData(products);
      setAllProductsData(allProducts);
    }
  }, [productTypeData, productTypeLoading, productTypeError]);
  useEffect(() => {
    productTypeRefetch({
      customerId: userId,
    });
  }, []);

  return (
    <div
      style={{
        minHeight: "80vh",
        maxHeight: "calc(99vh - 100px)",
        overflowY: "auto",
        overflowX: "hidden",
        paddingBottom: "20px",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        mt={2}
        p={2}
      >
        <Typography
          fontSize={18}
          fontFamily="Roboto"
          color="#181D8C"
          fontWeight={600}
          mb={1}
        >
          Products / Services
        </Typography>
        <PheonixBreadcrumbs breadcrumbText={breadcrumbText} />
      </Box>

      <Box
        sx={{
          padding: "20px",
          margin: "0px 20px 20px 20px",
          borderRadius: "10px",
          backgroundColor: "#F8F8FE",
        }}
        boxShadow={2}
      >
        <Box display="flex" justifyContent={"space-between"}>
          <Typography
            fontSize={16}
            fontWeight={500}
            color="#212121"
            textAlign="left"
            fontFamily={"Inter"}
            mb={3}
          >
            My active products
          </Typography>
          <Box display="flex" justifyContent={"space-between"}>
            <img
              src={ProdImage}
              alt={"image"}
              style={{ width: "24px", height: "24px", marginRight: "5px" }}
            />
            <Button
              sx={{
                color: "#181D8C",
                textTransform: "none",
                backgroundColor: "#F8F8FE",
                fontWeight: 600,
                fontSize: "12px",
                height: "32px",
                padding: "0 12px",
                minHeight: "unset",
                mt: -0.5,
                borderRadius: "8px",
                boxShadow: "2px 2px 10px 0px rgba(106, 95, 190, 0.15)",
                lineHeight: 1,
              }}
              onClick={() => handleView()}
            >
              {TEXT_MESSAGES.VIEW_TEXT}
            </Button>
          </Box>
        </Box>
        <Slider {...sliderSettings} className="horizontal-slider">
          {productsData.map((product: any, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </Slider>
      </Box>
      <Box
        sx={{ marginLeft: "20px", paddingLeft: "20px", marginBottom: "30px" }}
      >
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          sx={{ display: "flex", flexWrap: "nowrap" }}
        >
          <Grid
            item
            xs={8}
            sx={{
              marginTop: "20px",
              border: "0.5px solid #B7BAFF",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PheonixDataGrid
              rows={productServiceData.map((row, index) => ({
                ...row,
                id: `${row.order_id}_${index}_${Math.random()}`,
              }))}
              columns={columns}
              pageSizeOptions={[5, 10, 20, 30]}
              style={{
                border: "0px",
                marginLeft: "10px",
                borderRadius: "10px",
              }}
              searchQuery={searchQuery}
              rowCount={totalPages}
              onSearchQueryChange={handleSearchQueryChange}
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              loading={productLoading}
            />
          </Grid>

          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              marginLeft: "10px",
              backgroundColor: "#F8F8FE",
            }}
          >
            <Box
              p={2}
              borderRadius={2}
              boxShadow={3}
              sx={{ height: "445px", width: "90%" }}
            >
              <Box display="flex" justifyContent={"space-between"}>
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  color="#181D8C"
                  textAlign="left"
                  mb={4}
                  mt={3}
                >
                  {TEXT_MESSAGES.SERVICE_TEXT}
                </Typography>
                <Button
                  sx={{
                    color: "#181D8C",
                    textTransform: "none",
                    backgroundColor: "#F8F8FE",
                    fontWeight: 600,
                    fontSize: "12px",
                    height: "32px",
                    padding: "0 12px",
                    minHeight: "unset",
                    mt: 1.5,
                    borderRadius: "8px",
                    boxShadow: "2px 2px 10px 0px rgba(106, 95, 190, 0.15)",
                    lineHeight: 1, 
                  }}
                  onClick={() => handleView()}
                >
                 {TEXT_MESSAGES.VIEW_TEXT}
                </Button>
              </Box>
              <Slider {...verticalSliderSettings}  className="vertical-slider">
                {servicesData.map((service, index) => (
                  <ServicesCard key={index} service={service} />
                ))}
              </Slider>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Products;

import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './index.css';
import App from './App';
import SignUp from './Container/Login/Register';
import { SignUpContextProvider } from './Contexts/SignUpContext';
import Login from './Container/Login/Login';
import Confirmation from './Pages/ConfirmMail';
import ForgotPassword from './Container/Login/ForgotPassword';
import RequestOTP from './Container/Login/RequestOTP';
import VerifyOTP from './Container/Login/VerifyOTP';
import Dashboard from './Pages/Dashboard';
import Layout from './Pages/Layout';
import Products from './Pages/MyProducts';
import Home from './Pages/Home';
import Plans from './Pages/Plans';
import ProductGridView from './Pages/Products';
import ViewProduct from './Pages/ViewProduct';
import Orders from './Container/Order/Orders';
import Quotes from './Container/Quotation/Quotes';
import Invoice from './Container/Invoice/Invoice';
import Header from './Pages/Header';
import Report from './Pages/Report';
import { SessionContextProvider, useSession } from './Contexts/SessionContext';
import ProfileTab from './Container/Profile/ProfileTab';
import Customer from './Pages/Customer';
import Newcustomer from './Pages/Newcustomer';
import QuotationRequest from './Container/Quotation/QuotationRequest';
import { api } from './api';
import { ApolloProvider, InMemoryCache, ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { ApolloLink,Observable } from "@apollo/client";
import { createRoot } from 'react-dom/client';
import { ERROR_MESSAGES } from './const';
import { SidebarProvider } from './Pages/SidebarContext';
import  Payment  from '../src/Container/Payment/PaymentPage';
const httpLink = createHttpLink({
  uri: api.baseUrl,
});
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions?.code === "UNAUTHENTICATED" || err.message.includes("403")) {
        alert(ERROR_MESSAGES.SESSION_VALIDATION);
        window.location.href = '/'; 
        return;
      }
    }
  }
  if (networkError) {
    const errorMessage = networkError.message || '';
    if (errorMessage.includes(ERROR_MESSAGES.RESPONSE_ERROR)) {
      alert(ERROR_MESSAGES.SESSION_VALIDATION);
      window.location.href = '/';  
    }
    console.error(`[Network error]: ${networkError}`);
  }
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

const AppRouter = () => {
  const { isLoggedIn } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  useLayoutEffect(() => {
    if (!isLoggedIn && !['/confirm/', '/signup', '/requestotp', '/verifyotp', '/forgotpassword']
      .some(route => location.pathname.startsWith(route))) {
      navigate('/', { replace: true });
    }
  }, [isLoggedIn, location.pathname, navigate]);
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/requestotp" element={<RequestOTP />} />
      <Route path="/verifyotp" element={<VerifyOTP />} />
      <Route path="/confirm/:token" element={<Confirmation />} />
      {isLoggedIn ? (
        <>
          <Route path="/header" element={<Header />} />
          <Route path="/dashboard" element={<Layout title="Dashboard"><Dashboard /></Layout>} />
          <Route path="/home" element={<Layout title="Home"><Home /></Layout>} />
          <Route path="/solutions" element={<Layout title="Product/Service"><Products /></Layout>} />
          <Route path="/customer" element={<Layout title="Customer"><Customer /></Layout>} />
          <Route path="/plans" element={<Layout title="Products / Service"><Plans /></Layout>} />
          <Route path="/productgridview" element={<Layout title="Products / Service"><ProductGridView /></Layout>} />
          <Route path="/product" element={<Layout title="Product"><ViewProduct /></Layout>} />
          <Route path="/service" element={<Layout title="Service"><ViewProduct /></Layout>} />
          <Route path="/quotationrequest" element={<Layout title="Quotation Request"><QuotationRequest /></Layout>} />
          <Route path="/quotation" element={<Layout title="Quotation"><Quotes /></Layout>} />
          <Route path="/quotation/:quotationNo" element={<Layout title="Quotation"><Quotes /></Layout>} />
          <Route path="/orders" element={<Layout title="Orders"><Orders /></Layout>} />
          <Route path="/invoice" element={<Layout title="Invoice"><Invoice /></Layout>} />
          <Route path="/report" element={<Layout title="Report"><Report /></Layout>} />
          <Route path="/profile" element={<Layout title="Profile"><ProfileTab /></Layout>} />
          <Route path="/newcustomer" element={<Layout title="New Customer"><Newcustomer /></Layout>} />
          <Route path="/payments" element={<Layout title="Payment"><Payment /></Layout>} />
        </>) : (
        <Route path="*" element={<App />} />
      )}
    </Routes>
  );
};
const rootElement = document.getElementById('root');
document.body.style.backgroundColor = "#F0F0F0";
if (rootElement) {
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
      <Router>
        <SessionContextProvider>
          <SignUpContextProvider>
          <SidebarProvider>
            <AppRouter />
          </SidebarProvider>
          </SignUpContextProvider>
        </SessionContextProvider>
      </Router>
      </ApolloProvider>
    </React.StrictMode>,
    rootElement
  );
}
import React, { ReactNode } from 'react';
import Header from '../Pages/Header';
import AppBarProps from '../interfaces/AppBarProps';
import PheonixFooter from './PheonixFooter';

const AppBar: React.FC<AppBarProps> = ({ title, children, isSidebarOpen }) => {
return (
    <div style={{ backgroundColor: 'rgb(240, 240, 240)', height: '40px', padding: '7px', borderBottom: '0px solid #ccc', width: '100%', display: 'flex', alignItems: 'center', position: 'relative', zIndex: 1 }}>
      <h2 style={{ fontSize: '24px', fontWeight: '700',flex: '1' }}></h2>
      <Header />
      {children}
      <PheonixFooter style={{left:"90px"}}/>
    </div>
  );
};
export default AppBar;